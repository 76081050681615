@use '@angular/material' as mat;
@use "node_modules/@angular/cdk/overlay" as cdk-overlay;
@use 'styles/components/split-button' as mas-split-button;

@import 'node_modules/@swimlane/ngx-datatable/index.scss';
@import 'node_modules/@swimlane/ngx-datatable/themes/material.scss';
@import 'node_modules/@swimlane/ngx-datatable/assets/icons.css';


@import 'ag-grid-community/styles/ag-grid.css';
/* Quartz Theme Specific CSS */
@import 'ag-grid-community/styles/ag-theme-material.css';
.ag-cell {
  display: flex;
  align-items: center;
}
.ag-cell-wrapper {
  flex: 1;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

/* You can add global styles to this file, and also import other style files */

@include cdk-overlay.overlay();

@include mat.core();

$primary: mat.define-palette(mat.$red-palette);
$accent: mat.define-palette(mat.$blue-palette);
$warn: mat.define-palette(mat.$yellow-palette);

$my-theme: mat.define-light-theme((
  color: (
    primary: $primary,
    accent: $accent,
    warn: $warn,
  ),
  density: 0,
));

//@include mat.core-theme($my-theme);
//@include mat.progress-bar-theme($my-theme);
//@include mat.progress-spinner-theme($my-theme);

// for forms
@include mat.all-component-themes($my-theme);


// Emit core Split Button styles.
@include mas-split-button.core-styles;
// Emit styles that depend on the theme config.
@include mas-split-button.theme($my-theme);


.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  display: flex;
  align-items: center;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell-label {
  flex: 1;
}

.ngx-datatable.material .datatable-body .datatable-body-row .datatable-body-cell {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.ngx-datatable.material {
  .datatable-body-row {
    border-bottom: 1px solid rgb(17 24 39 / 0.05);
  }
}

@import "styles/index";
