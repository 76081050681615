header {
  width: 100%;
  background-color: #fff;
  border-bottom: 1px solid #d8d8d8;
  align-items: center;
  display: flex;
  position: relative;

  .header {
    display: flex;
    width: 100%;
  }

  .PreHeader {
    grid-area: PreHeader;
  }

  .SiteLogo {
    width: 90px;
    grid-area: SiteLogo;
  }

  .SiteContext {
    grid-area: SiteContext;
  }

  .SiteSearch {
    grid-area: SiteSearch;
  }

  .SiteLogin {
    grid-area: SiteLogin;
  }

  .NavigationBar {
    width: 100%;
  }

  .SiteLogo,
  .SiteContext,
  .PreHeader {
    align-self: center;
    display: block;
    flex: none;
  }

  .NavigationBar {
    flex: 100%;
    align-self: center;
    display: block;
  }
}
